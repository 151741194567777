import http from "../../api/axios"
import {
  ICreateLocation,
  IUpdateLocation,
} from "../../models/locations/request"

import { IRequestParams } from "../../utils/api/base-listing-response"

class LocationsService {
  getAllLocations = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/LocationService/GetAll", {
      params,
    })

  getLocation = (id: number): Promise<any> =>
    http.get("/services/app/LocationService/Get", { params: { Id: id } })

  createLocation = (data: ICreateLocation): Promise<any> =>
    http.post("/services/app/LocationService/Create", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  updateLocation = (data: IUpdateLocation): Promise<any> =>
    http.put("/services/app/LocationService/Update", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  deleteLocation = (id: number): Promise<any> =>
    http.delete("/services/app/LocationService/Delete", {
      params: { Id: id },
    })
}

export default LocationsService
