import http from "../../api/axios"
import { ICreateCountry, IUpdateCountry } from "../../models/countries/request"
import { IRequestParams } from "../../utils/api/base-listing-response"

class CountryService {
  getAllCountries = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/CountriesService/GetAll", { params })

  getCountry = (id: number): Promise<any> =>
    http.get("/services/app/CountriesService/Get", {
      params: { Id: id },
    })

  createCountry = (data: ICreateCountry): Promise<any> =>
    http.post("/services/app/CountriesService/Create", data)

  updateCountry = (data: IUpdateCountry): Promise<any> =>
    http.put("/services/app/CountriesService/Update", data)

  deleteCountry = (id: number): Promise<any> =>
    http.delete("/services/app/CountriesService/Delete", {
      params: { Id: id },
    })
}

export default CountryService
