import http from "../../api/axios"
import { ICreateRole, IUpdateRole } from "../../models/roles/request"

import { IRequestParams } from "../../utils/api/base-listing-response"

class RolesService {
  getAllRoles = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/Role/GetAll", {
      params,
    })

  getRole = (id: number): Promise<any> =>
    http.get("/services/app/Role/Get", { params: { Id: id } })

  createRole = (data: ICreateRole): Promise<any> =>
    http.post("/services/app/Role/Create", data)

  updateRole = (data: IUpdateRole): Promise<any> =>
    http.put("/services/app/Role/Update", data)

  deleteRole = (id: number): Promise<any> =>
    http.delete("/services/app/Role/Delete", {
      params: { Id: id },
    })
}

export default RolesService
