import { createContext } from "react"
// import { ILoginRequest } from '../../models/auth/request'
import { ACCESS_TOKEN, DEFAULT_FUNCTION } from "../../utils/helpers/constants"
import { IUserLogin } from "../../models/user-login/response"
import { ILogin } from "../../models/user-login/reques"

export type AuthLoading = "login" | "logout" | "change_password" | "roles"

export interface IInternalState {
  loading: AuthLoading[]

  isAuthenticated?: boolean
  authUser?: IUserLogin
  userPermissions: string[]
  //   userPermissions: Permission[]
}

export const internalState: IInternalState = {
  loading: [],
  isAuthenticated: !localStorage.getItem(ACCESS_TOKEN) ? false : true,
  userPermissions: [
    "Pages.Home",
    "Pages.Booking",
    "Pages.Trips",
    "Pages.Inventory",
    "Pages.Affiliate",
    "Pages.Countries",
    "Pages.Users",
    "Pages.Roles",
    "Pages.Parnter",
    "Pages.Experince",
    "Pages.Locations",
    "Pages.PromoCodes",
    "Pages.Settings",
    "Pages.Users.Activation",
    "Create",
    "Update",
    "Delete",
    "Read",
  ],
}

export interface IExternalState extends IInternalState {
  actions: {
    login: (request: ILogin) => void
    logout: () => void
    changePassword: (data: any) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    login: DEFAULT_FUNCTION,
    logout: DEFAULT_FUNCTION,
    changePassword: DEFAULT_FUNCTION,
  },
}

const AuthContext = createContext(externalState)

export default AuthContext
