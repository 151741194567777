import http from "../../api/axios"
import {
  ICreateCategory,
  IUpdateCategory,
} from "../../models/categories/request"

import { IRequestParams } from "../../utils/api/base-listing-response"

class CategoriesService {
  getAllCategories = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/CategoriesService/GetAll", { params })

  getCategory = (id: number): Promise<any> =>
    http.get("/services/app/CategoriesService/Get", { params: { Id: id } })

  createCategory = (data: ICreateCategory): Promise<any> =>
    http.post("/services/app/CategoriesService/Create", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  updateCategory = (data: IUpdateCategory): Promise<any> =>
    http.put("/services/app/CategoriesService/Update", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  deleteCategory = (id: number): Promise<any> =>
    http.delete("/services/app/CategoriesService/Delete", {
      params: { Id: id },
    })
}

export default CategoriesService
