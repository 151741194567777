import http from "../../api/axios"
import { ICreateUser, IUpdateUser } from "../../models/users/request"

import { IRequestParams } from "../../utils/api/base-listing-response"

class UsersService {
  getAllUsers = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/User/GetAll", {
      params,
    })

  getAllCustomers = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/User/GetAllCustomers", {
      params,
    })
  getAllAdmins = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/User/GetAllSysAdmins", {
      params,
    })

  getUser = (id: number): Promise<any> =>
    http.get("/services/app/User/Get", { params: { Id: id } })

  createUser = (data: ICreateUser): Promise<any> =>
    http.post("/services/app/User/Create", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  updateUser = (data: IUpdateUser): Promise<any> =>
    http.put("/services/app/User/Update", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  deleteUser = (id: number): Promise<any> =>
    http.delete("/services/app/User/Delete", {
      params: { Id: id },
    })
}

export default UsersService
