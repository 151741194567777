import http from "../../api/axios"
import { ILogin } from "../../models/user-login/reques"
import { IUserLogin } from "../../models/user-login/response"
import User from "../../pages/users"

class AuthService {
  constructor() {}
  login = (data: ILogin): Promise<any> =>
    http.post("/TokenAuth/Authenticate", data)

  // logout = (params?: RequestParams) =>
  //   this.http.request<void>({
  //     path: `/auth/sign-out`,
  //     method: "POST",
  //     ...params,
  //   })

  me = () => http.get("/services/app/Session/GetCurrentLoginInformations")

  getUserPermissions = (id: number): Promise<any> =>
    http.get("/services/app/User/getUserPermissions", { params: { id: id } })

  changePassword = (data: ILogin): Promise<any> =>
    http.post("/services/app/User/ChangePassword", data)
}

export default AuthService
