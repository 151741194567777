import http from "../../api/axios"
import { ICreatePartner, IUpdatePartner } from "../../models/partners/request"

import { IRequestParams } from "../../utils/api/base-listing-response"

class PartnersService {
  getAllPartners = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/PartnersService/GetAll", {
      params,
    })

  getPartner = (id: number): Promise<any> =>
    http.get("/services/app/PartnersService/Get", { params: { Id: id } })

  createPartner = (data: ICreatePartner): Promise<any> =>
    http.post("/services/app/PartnersService/Create", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  updatePartner = (data: IUpdatePartner): Promise<any> =>
    http.put("/services/app/PartnersService/Update", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  deletePartner = (id: number): Promise<any> =>
    http.delete("/services/app/PartnersService/Delete", {
      params: { Id: id },
    })
}

export default PartnersService
