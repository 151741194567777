import React, { useEffect, useReducer } from "react";
import AuthContext, { internalState } from "./context";
import reducer from "./reducer";
// import { useHistory } from 'react-router-dom'
// import { httpclient } from '../../services/http-client'
import { execute } from "../../utils/api/api-execute";
// import EndPoints from "../../services/end-points"
import {
  errorNotification,
  successNotification,
} from "../../utils/helpers/notification";
// import eventManager, {
//   EVENT_ERORR,
//   EVENT_FORBIDDEN,
//   EVENT_SUCCESS,
//   EVENT_UNAOUTHORIZED,
// } from '../../utils/events'
import http from "../../api/axios";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";
import { useNavigate } from "react-router-dom";
import { LOGIN_PAGE } from "../../pages/paths";
import { ILogin } from "../../models/user-login/reques";
import EndPoints from "../../services/end-points";

interface IProps {
  children: React.ReactNode;
}
const AuthContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const navigate = useNavigate();
  /**
   * Events
   */
  useEffect(() => {
    // eventManager.on(EVENT_SUCCESS, (message?: string) => {
    //   successNotification(message ?? t("operationDoneSuccessfully"))
    // })
    // eventManager.on(EVENT_ERORR, (message) => {
    //   errorNotification(message)
    // })
    // eventManager.on(EVENT_UNAOUTHORIZED, () => {
    //   history.replace("/auth/login")
    // })
    // eventManager.on(EVENT_FORBIDDEN, () => {
    //   history.replace("/403")
    // })
  }, []);

  useEffect(() => {
    if (state.isAuthenticated) {
      me();
    }
  }, [state.isAuthenticated]);

  // useEffect(() => {
  //   if (state.isAuthenticated && state.userPermissions.length === 0) {
  //     getPermissions()
  //   }
  // }, [state.authUser])

  // Me
  const me = async () => {
    await execute({
      callback: async () => {
        if (!state.authUser) {
          const { data } = await EndPoints.auth.me();

          dispatch({ type: "USER", payload: { user: data?.result?.user } });

          const permissionsData = await EndPoints.auth.getUserPermissions(
            data?.result?.user.id
          );

          dispatch({
            type: "GET_PERMISSIONS",
            payload: { permissions: permissionsData.data.result },
          });

          successNotification(
            "Logged in successfully.",
            `Welcome ${data?.result?.user?.emailAddress}, hope you are doing great!.`
          );
        }
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT" });
      },
      finallyCallback: () => {},
      throwException: false,
    });
  };

  // Login
  const login = async (request: ILogin) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "login" } });

        const { data } = await EndPoints.auth.login(request);

        localStorage.setItem(ACCESS_TOKEN, data.result.accessToken);

        dispatch({
          type: "LOGIN",
          payload: { user: data?.user },
        });
      },
      fallback: (error) => {
        errorNotification("Unsuccessful Operation");
        dispatch({ type: "LOGOUT" });
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "login" } });
      },
      throwException: false,
    });
  };

  // Logout
  const logout = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "logout" } });

        successNotification(
          "Logged out successfully.",
          "Goodbye, have a great day!."
        );

        dispatch({ type: "LOGOUT" });

        localStorage.removeItem(ACCESS_TOKEN);

        navigate(LOGIN_PAGE, { replace: true });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "logout" } });
      },
      throwException: false,
    });
  };

  // Logout
  const changePassword = async (data: any) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "change_password" } });

        await EndPoints.auth.changePassword(data);
        successNotification(
          "Changed Successfully successfully.",
          "Redirecting"
        );

        dispatch({ type: "LOGOUT" });
        localStorage.removeItem(ACCESS_TOKEN);
        navigate(LOGIN_PAGE, { replace: true });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "change_password" } });
      },
      throwException: false,
    });
  };

  const getPermissions = async () => {
    if (state.authUser) {
      const permissionsData = await EndPoints.auth.getUserPermissions(
        state.authUser.id
      );

      dispatch({
        type: "GET_PERMISSIONS",
        payload: { permissions: permissionsData.data.result },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        actions: {
          login,
          logout,
          changePassword,
        },
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
