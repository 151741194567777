import http from "../../api/axios"
import {
  ICreateInventoryType,
  IUpdateInventoryType,
} from "../../models/inventory-type/request"

import { IRequestParams } from "../../utils/api/base-listing-response"

class InventoryTypeService {
  getAllInventoryTypes = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/InventoryTypeService/GetAll", { params })

  getInventoryType = (id: number): Promise<any> =>
    http.get("/services/app/InventoryTypeService/Get", { params: { Id: id } })

  createInventoryType = (data: ICreateInventoryType): Promise<any> =>
    http.post("/services/app/InventoryTypeService/Create", data)

  updateInventoryType = (data: IUpdateInventoryType): Promise<any> =>
    http.put("/services/app/InventoryTypeService/Update", data)

  deleteInventoryType = (id: number): Promise<any> =>
    http.delete("/services/app/InventoryTypeService/Delete", {
      params: { Id: id },
    })
}

export default InventoryTypeService
