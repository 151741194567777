import http from "../../api/axios"
import {
  ICreateActivity,
  IUpdateActivity,
} from "../../models/activities/request"
import { IRequestParams } from "../../utils/api/base-listing-response"

class ActivitiesService {
  getAllActivities = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/ActivitiesService/GetAll", { params })

  getActivity = (id: number): Promise<any> =>
    http.get("/services/app/ActivitiesService/Get", { params: { Id: id } })

  createActivity = (data: ICreateActivity): Promise<any> =>
    http.post("/services/app/ActivitiesService/Create", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  updateActivity = (data: IUpdateActivity): Promise<any> =>
    http.put("/services/app/ActivitiesService/Update", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  deleteActivity = (id: number): Promise<any> =>
    http.delete("/services/app/ActivitiesService/Delete", {
      params: { Id: id },
    })
}

export default ActivitiesService
