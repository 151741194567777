import http from "../../api/axios"
import {
  ICreateAffiliateMarketingLink,
  IUpdateAffiliateMarketingLink,
} from "../../models/affiliate-marekting-link/request"
import { IRequestParams } from "../../utils/api/base-listing-response"

class AffiliateMarketingLinkService {
  getAllAffiliateMarketingLinks = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/AffiliateMarketingLinkService/GetAll", { params })

  getAffiliateMarketingLink = (id: number): Promise<any> =>
    http.get("/services/app/AffiliateMarketingLinkService/Get", {
      params: { Id: id },
    })

  createAffiliateMarketingLink = (
    data: ICreateAffiliateMarketingLink
  ): Promise<any> =>
    http.post("/services/app/AffiliateMarketingLinkService/Create", data)

  updateAffiliateMarketingLink = (
    data: IUpdateAffiliateMarketingLink
  ): Promise<any> =>
    http.put("/services/app/AffiliateMarketingLinkService/Update", data)

  deleteAffiliateMarketingLink = (id: number): Promise<any> =>
    http.delete("/services/app/AffiliateMarketingLinkService/Delete", {
      params: { Id: id },
    })
}

export default AffiliateMarketingLinkService
