import http from "../../api/axios"
import { ICreateAmenity, IUpdateAmenity } from "../../models/amenities/request"
import { IRequestParams } from "../../utils/api/base-listing-response"

class AmenitiesService {
  getAllAmenities = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/AmenitiesService/GetAll", { params })

  getAmenity = (id: number): Promise<any> =>
    http.get("/services/app/AmenitiesService/Get", { params: { Id: id } })

  createAmenity = (data: ICreateAmenity): Promise<any> =>
    http.post("/services/app/AmenitiesService/Create", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  updateAmenity = (data: IUpdateAmenity): Promise<any> =>
    http.put("/services/app/AmenitiesService/Update", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  deleteAmenity = (id: number): Promise<any> =>
    http.delete("/services/app/AmenitiesService/Delete", {
      params: { Id: id },
    })
}

export default AmenitiesService
