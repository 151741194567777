import http from "../../api/axios"
import {
  ICreatePromoCode,
  IUpdatePromoCode,
} from "../../models/promo-code/request"

import { IRequestParams } from "../../utils/api/base-listing-response"

class PromoCodeService {
  getAllPromoCodes = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/PromoCodesService/GetAll", {
      params,
    })

  getPromoCode = (id: number): Promise<any> =>
    http.get("/services/app/PromoCodesService/Get", { params: { Id: id } })

  createPromoCode = (data: ICreatePromoCode): Promise<any> =>
    http.post("/services/app/PromoCodesService/Create", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  updatePromoCode = (data: IUpdatePromoCode): Promise<any> =>
    http.put("/services/app/PromoCodesService/Update", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  deletePromoCode = (id: number): Promise<any> =>
    http.delete("/services/app/PromoCodesService/Delete", {
      params: { Id: id },
    })
}

export default PromoCodeService
