import http from "../../api/axios"
import { ICreateCity, IUpdateCity } from "../../models/cities/request"
import { IRequestParams } from "../../utils/api/base-listing-response"

class CitiesService {
  getAllCities = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/CitiesService/GetAll", { params })

  getCity = (id: number): Promise<any> =>
    http.get("/services/app/CitiesService/Get", { params: { Id: id } })

  createCity = (data: ICreateCity): Promise<any> =>
    http.post("/services/app/CitiesService/Create", data)

  updateCity = (data: IUpdateCity): Promise<any> =>
    http.put("/services/app/CitiesService/Update", data)

  deleteCity = (id: number): Promise<any> =>
    http.delete("/services/app/CitiesService/Delete", {
      params: { Id: id },
    })
}

export default CitiesService
