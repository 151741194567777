import http from "../../api/axios"
import {
  ICreateExperience,
  IUpdateExperience,
} from "../../models/experience/request"
import { IRequestParams } from "../../utils/api/base-listing-response"

class ExperienceService {
  getAllExperiences = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/Experience/GetAll", { params })

  getExperience = (id: number): Promise<any> =>
    http.get("/services/app/Experience/Get", {
      params: { Id: id },
    })

  createExperience = (data: ICreateExperience): Promise<any> =>
    http.post("/services/app/Experience/Create", data)

  updateExperience = (data: IUpdateExperience): Promise<any> =>
    http.put("/services/app/Experience/Update", data)

  deleteExperience = (id: number): Promise<any> =>
    http.delete("/services/app/Experience/Delete", {
      params: { Id: id },
    })
}

export default ExperienceService
