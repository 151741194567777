import http from "../../api/axios"
import {
  ICreateEquipment,
  IUpdateEquipment,
} from "../../models/equipments/request"
import { IRequestParams } from "../../utils/api/base-listing-response"

class EquipmentsService {
  getAllEquipments = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/EquipmentsService/GetAll", { params })

  getEquipment = (id: number): Promise<any> =>
    http.get("/services/app/EquipmentsService/Get", { params: { Id: id } })

  createEquipment = (data: ICreateEquipment): Promise<any> =>
    http.post("/services/app/EquipmentsService/Create", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  updateEquipment = (data: IUpdateEquipment): Promise<any> =>
    http.put("/services/app/EquipmentsService/Update", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  deleteEquipment = (id: number): Promise<any> =>
    http.delete("/services/app/EquipmentsService/Delete", {
      params: { Id: id },
    })
}

export default EquipmentsService
