import http from "../../api/axios";

import { IRequestParams } from "../../utils/api/base-listing-response";

class BookingsService {
  getAllBookings = (params?: any): Promise<any> =>
    http.get("/services/app/BookingServices/GetAll", { params });

  getAllPartnerBookings = (params?: any): Promise<any> =>
    http.get("/services/app/BookingServices/GetAllWithTotals", { params });

  getBooking = (id: number): Promise<any> =>
    http.get("/services/app/BookingServices/Get", { params: { Id: id } });

  createBooking = (data: any): Promise<any> =>
    http.post("/services/app/BookingServices/Create", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

  updateBooking = (data: any): Promise<any> =>
    http.put("/services/app/BookingServices/Update", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

  deleteBooking = (id: number): Promise<any> =>
    http.delete("/services/app/BookingServices/Delete", {
      params: { Id: id },
    });
}

export default BookingsService;
