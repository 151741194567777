import http from "../../api/axios"
import { ICreateTrip, IUpdateTrip } from "../../models/trips/request"

import { IRequestParams } from "../../utils/api/base-listing-response"

class TripsService {
  getAllTrips = (params?: IRequestParams): Promise<any> =>
    http.get("/services/app/TripsService/GetAll", {
      params,
    })

  getTrip = (id: number): Promise<any> =>
    http.get("/services/app/TripsService/Get", { params: { Id: id } })

  createTrip = (data: ICreateTrip): Promise<any> =>
    http.post("/services/app/TripsService/Create", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  updateTrip = (data: IUpdateTrip): Promise<any> =>
    http.put("/services/app/TripsService/Update", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

  deleteTrip = (id: number): Promise<any> =>
    http.delete("/services/app/TripsService/Delete", {
      params: { Id: id },
    })
}

export default TripsService
