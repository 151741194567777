import ActivitiesService from "./activities"
import AffiliateMarketingLinkService from "./affiliate-marketing-link"
import AmenitiesService from "./amenities"
import AuthService from "./auth"
import BookingsService from "./bookings"
import CategoriesService from "./category"
import CitiesService from "./cities"
import CountryService from "./countries"
import EquipmentsService from "./equipments"
import ExperienceService from "./experience"
import InventoryTypeService from "./inventory-type"
import LocationsService from "./locations"
import PartnersService from "./partners"
import PromoCodeService from "./promo-code"
import RolesService from "./role"
import TripsService from "./trips"
import UsersService from "./users"

export default class EndPoints {
  public static auth = new AuthService()
  public static activity = new ActivitiesService()
  public static city = new CitiesService()
  public static category = new CategoriesService()
  public static partner = new PartnersService()
  public static amenity = new AmenitiesService()
  public static equipment = new EquipmentsService()
  public static location = new LocationsService()
  public static trip = new TripsService()
  public static inventoryType = new InventoryTypeService()
  public static affiliateMarketingLink = new AffiliateMarketingLinkService()
  public static experience = new ExperienceService()
  public static user = new UsersService()
  public static role = new RolesService()
  public static country = new CountryService()
  public static booking = new BookingsService()
  public static promoCode = new PromoCodeService()
}
